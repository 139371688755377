$splitpane-resizer-bgcolor : darkgray;

$travelbook-navbar-bgcolor: #036DA1;    /* couleur de fond du div carnet de voyage courant */

$step-navbar-bgcolor: #f8ba48;  /* couleur de fond du div etape courante */

$note-link-color: blue;

$travelbook-date-color: #911208;

$travelbook-navbar-height: 3.2rem;   /* hauteur du div carnet de voyage courant */

$step-navbar-height: 2.7rem;   /* hauteur du div etape courante */

$steps-slider-height: 0.5rem;   /* hauteur du div slider des étapes */

$viewer-height: calc(100% - 6.4rem);   /* hauteur du div scrolllist des etapes & notes */

$comment-navbar-height: 2.7em;   /* hauteur du div commentaires */