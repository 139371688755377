@import "../../styles/common.scss";

// barre de menu page d'accueil
.home-navbar-container {
  padding: 0;
  background-color: $travelbook-navbar-bgcolor;
  color: white;
  width: 100%;
  height: 3.2rem; /* hauteur du div travelbook */
  display: flex !important;
  justify-content: space-between !important;
  align-items: center;
  z-index: 820;

  .title {
    width: 100%;
    padding: 0 0 0 15px;
    margin: 0;

    a {
      font-size: 1.4em;
      font-weight: 500;
      color: white;
      text-decoration: none;
    }
  }

  .navbar-container {
    display: flex;
    align-items: center;

    .toggle {
      background: transparent;
      border: none;
      margin-right: 0;
    }

    .toggle-icon {
      font-size: 1.4em;
      color: white;
    }
  }

  .menu {
    top: -4px !important;
    right: 25px !important;
  }
}

.travelbooks-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

// item de la liste carnet de voyage
.travelbook-item {
  flex: none;
  width: 25rem;
  margin: 10px;
}
