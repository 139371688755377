@import "../../styles/common.scss";

html,
body {
  height: 100vh; /* indispensable pour occuper toute la hauteur du device */
  margin: 0;
  padding: 0;
}

/* styles pour l'organisation des panneaux */
.base-container {
  display: flex;
  flex-direction: column; /* permet de mettre la carte en premier */

  min-height: 100vh;
  height: 100vh; /* indispensable pour propager la hauteur disponible */
  width: 100vw;
  min-width: 100vw;
}

.resizer {
  background-color: $splitpane-resizer-bgcolor;
  cursor: ns-resize !important;
  height: 2px;
  width: 100vw;
  z-index: 1000;
  position: relative;

  /* bande transparente au dessus de la carte pour attraper l'evt touchStart */
  .handle {
    position: absolute;
    width: 100%;
    height: 20px;
    background-color: transparent;
    top: -20px;
    left: 0;
  }
}

.topright-container {
  /* Initial height */
  background-color: white;
  height: 30%;
  width: 100vw;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bottomleft-container {
  /* Take the remaining height */
  width: 100vw;
  background-color: white;
  flex: 1;

  display: flex;
  flex-direction: column;
  overflow: auto; /* pour la scroll list */
}

@media screen and (min-width: 768px) {
  .base-container {
    display: flex;
    flex-direction: row-reverse; /* permet de mettre le panel a gauche */
  }

  .resizer {
    background-color: $splitpane-resizer-bgcolor;
    cursor: ew-resize !important;
    height: 100vh;
    width: 3px;
    z-index: 1000;
    position: relative;

    /* bande transparente au dessus de la carte pour attraper l'evt touchStart */
    .handle {
      position: absolute;
      width: 20px;
      height: 100%;
      background-color: transparent;
      top: 0;
      left: 0;
    }
  }

  .topright-container {
    width: 50%; /* la moitie au depart */
    height: 100vh;
    background-color: white;

    display: flex;
    flex-direction: row;
  }

  .bottomleft-container {
    flex: 1; /* prendre la place restante */
    height: 100vh;
    background-color: white;

    display: flex;
    flex-direction: column;
  }
}

#map {
  width: 100px;
  height: 100px;
  min-height: 100%;
  min-width: 100%;
}

#panel {
  height: 100%;
  width: 100%; /* indispensable pour propager la taille du div a la scrollList */
}

/* container administration du carnet de voyage courant */
.travelbook-navbar-container {
  padding: 0;
  background-color: $travelbook-navbar-bgcolor;
  color: white;
  width: 100%;
  height: $travelbook-navbar-height; /* hauteur du div travelbook */
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  z-index: 820;

  .title {
    padding: 0 0 0 15px;
    margin: 0;

    a {
      font-size: 1.4em;
      font-weight: 500;
      color: white;
      text-decoration: none;
    }
    a span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
    }

    a span:after {
      content: "\00ab";
      position: absolute;
      opacity: 0;
      top: 0;
      left: 0;
      transition: 0.5s;
    }

    a:hover span {
      padding-left: 25px;
    }

    a:hover span:after {
      opacity: 1;
      left: 0;
    }
  }

  .navbar-container {
    display: flex;
    align-items: center;

    .toggle {
      background: transparent;
      border: none;
      margin-right: 0;
    }

    .toggle-icon {
      font-size: 1.4em;
      color: white;
    }
  }

  .menu {
    top: -4px;
    right: 25px;
  }
}

/* container administration de l'etape courante */
.step-navbar-container {
  padding: 0;
  background-color: #f8ba48;
  color: black;
  width: 100%;
  height: $step-navbar-height; /* hauteur du div etape courante */
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  z-index: 810;

  .title {
    flex: 1;
    padding: 0 0 0 15px;
    margin: 0;

    a {
      font-size: 1.4em;
      font-weight: 500;
      color: white;
      text-decoration: none;
    }
  }
  .tdate {
    font-size: 0.8em;
    font-weight: 400;
    margin: 0;
    color: $travelbook-date-color;
  }
  .menu {
    top: 0;
    right: 25px;
  }
}

.note-navbar-container {
  padding: 0;
  width: 100%;
  height: 0 !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  position: relative;

  .menu {
    top: -2px;
    right: 25px;
  }
}

/* container slider des étapes */
.steps-slider-container {
  height: $steps-slider-height; /* hauteur du div slider des étapes */
  margin: 0 6px;
  width: calc(100% - 12px);

  .rc-slider {
    top: -0.5rem;
  }
}

#viewer-zone {
  /* hauteur du div contenant la scrollList ou le slider déduite de la hauteur disponible */
  height: $viewer-height;
  width: 100%;
  padding: 0;
  background-color: white;
}

/* container scroll liste des notes */
.notes-scrlist-container {
  /* hauteur du div scrolllist des etapes & notes */
  height: 100%;
  overflow-y: auto;

  .step {
    border-radius: 5px;
    border: 1px solid lightgray;
    margin: 3px 3px 3px 5px;
    padding: 0;
  }

  .current-step {
    border-right: 1px ridge darkgray;
  }
}

.note {
  padding: 15px 15px 0 15px;

  .note-desc {
    font-size: 1em;
    color: black;
    text-align: left;
    margin: 0 5px;
    z-index: 0;
  }

  .note-media {
    text-align: center;

    .note-image,
    .note-video {
      max-width: 100%;
      max-height: 768px;
      width: auto;
      height: auto;
      margin: auto;
      text-align: center;
    }
  }

  .note-desc + .note-media, 
  .note-media + .note-desc {
    margin-top: 10px;
  }

  .note-caption {
    font-size: 0.9em;
    color: black;
    margin: 0;
    line-height: 175%;
  }

  .navbar-nav {
    position: relative;
    top: 0.3em;
    padding: 5px 10px;

    a {
      color: $note-link-color;
      margin: 2px 5px;
    }
  }
}

/* container slider des notes */
.notes-slider-container {
  color: black;
  width: auto;
  /* hauteur du div slider des etapes & notes */
  height: 100%;
  overflow-y: auto;

  .carousel-inner {
    max-width: 100%;
    max-height: 100%;

    .carousel-item > img {
      position: relative;
      max-width: 100%;
      max-height: 100%;
    }
  }
}

/* Gestion des fleches du carousel - bootstrap 4. */
.carousel-control-prev {
  left: 15px;
}
.carousel-control-next {
  right: 15px;
}
.carousel-control-prev,
.carousel-control-next {
  top: 33%;
  bottom: 33%;
}
.carousel .carousel-control-prev, 
.carousel .carousel-control-next {
  visibility: hidden;
}
.carousel:hover .carousel-control-prev, 
.carousel:hover .carousel-control-next {
  visibility: visible;
}


/* container administration des commentaires */
.comment-navbar-container {
  padding: 0;
  color: $travelbook-navbar-bgcolor;
  background-color: white;
  width: 100%;
  height: $comment-navbar-height; /* hauteur du div commentaires */
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  z-index: 800;

  .comment-container {
    display: flex !important;
    justify-content: flex-end !important;

    .comment-button {
      background: transparent;
      border: none;
    }

    .comment-icon {
      font-size: 1.2em;
      color: grey;
      opacity: 0.7;
    }
  }
}
