/* dropdown menu */
.menu-container {
  padding: 0;
  flex: 1;
  margin: 0;
  position: relative;
  display: inline-block;

  .menu {
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 36px;
    right: 5px;
    width: 25em;
    z-index: 880;
    font-size: 0.9em;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  }

  .menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .menu ul {
    list-style: none;
    margin: 0;
    padding: 5px 0 5px 15px;
    /*text-align: right; */
  }

  .menu li .link-button {
    color: #333333;
    padding: 4px 4px 4px 0;
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
  }

  .menu-trigger {
    background: transparent;
    border: none;

    .menu-dark-icon {
      font-size: 1.2em;
      color: grey;
      opacity: 0.8;
    }

    .menu-light-icon {
      font-size: 1.2em;
      color: white;
    }
  }

  .menu-trigger {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 2px;
    border: none;
    vertical-align: middle;
    transition: box-shadow 0.4s ease;
    margin-right: 5px;
    /* Strictly for positioning */
  }

  .menu-trigger:hover {
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  }

  .menu-trigger span {
    font-weight: 700;
    vertical-align: middle;
    font-size: 14px;
    margin: 0;
  }
}
