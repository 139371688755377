html, body {
	font-family: verdana, helvetica, sans-serif;
  height: 100vh;  /* indispensable pour occuper toute la hauteur du device */
  margin: 0;
  padding: 0;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {
  clear: both;
  color: #24292e;
  font-weight: 600;
  margin-top: 24px;
  margin-bottom: 15px;
}
h1 {
  font-size: 32px;
  padding-bottom: 10px;
}
h2 {
  font-size: 24px;
  padding-bottom: 0.3em;
  line-height: 1.25;
}
h3 {
  font-size: 18px;
  line-height: 25px;
}
h4 {
  font-size: 16px;
  line-height: 20px;
}
h5 {
  font-size: 14px;
  line-height: 17.5px;
}

p {
  margin-bottom: 0.5em;
}